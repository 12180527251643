import React from 'react'
import styled from 'styled-components'
import ButtonShell from 'components/elements/Button/ButtonShell'
import { motion } from 'framer-motion'


const StyledButton = styled(motion.div)`
  background-color: ${props => props.theme.color.face.contrast};
  color: ${props => props.theme.color.text.light};
  display: inline-block;
  border-radius: 5px;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.light};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
    font-size: ${props => props.theme.font.size.m};
    font-weight: ${props => props.theme.font.weight.l};
    padding: 15px 40px 15px 40px;
    text-decoration: none !important;
  }
`

const ButtonAlt = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton
    whileHover={{ scale: 1.05 }}
    className={className}
  >
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonAlt