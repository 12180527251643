import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size['26']};
    font-weight: ${props => props.theme.font.weight.xl};
  }

 
  p {
    font-size: ${props => props.theme.font.size.sm};
    padding-right: 90px;
    line-height: 27px;
  }

  a {
    font-weight: ${props => props.theme.font.weight.l};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.color.text.contrast};
    }
  }
`

const BackgroundText = styled(ParseContent)`
  position: absolute;
  bottom: 30px;
  width: 75%;
  left: 0;
  color: rgba(255,255,255,0.47);
  z-index: 12;

  h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['73']};
    line-height: 93px;
  }

  @media screen and (max-width: 1200px) {
    bottom: 120px;

    h2 {
      font-size: ${props => props.theme.font.size['60']};
      line-height: 71px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: ${props => props.theme.font.size['60']};
    }

    padding: 0 15px 0 15px;
    bottom: 350px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    bottom: 420px;

    h2 {
      font-size: ${props => props.theme.font.size['50']};
      line-height: 61px;
    }
  }

  @media screen and (max-width: 576px) {
    bottom: 410px;

    h2 {
      font-size: ${props => props.theme.font.size.xxl};
      line-height: 50px;
    }
  }

  @media screen and (max-width: 496px) {
    bottom: 450px;
    
    h2 {
      font-size: ${props => props.theme.font.size['36']};
      line-height: 40px;
    }
  }

  @media screen and (max-width: 434px) {
    bottom: unset;
    top: -150px;
  }

  @media screen and (max-width: 423px) {
    bottom: unset;
    top: -150px;
  }

  @media screen and (max-width: 402px) {
    bottom: unset;
    top: -150px;
  }

  @media screen and (max-width: 360px) {
    top: -120px;
    
    h2 {
      font-size: ${props => props.theme.font.size['26']};
      line-height: 30px;
    }
  }
`

const ImageContainer = styled.div`
  /* width: 1440px; */
  max-height: 690px;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    background-color: ${props => props.theme.color.face.main};
    height: 177px;
    width: 100%;
    position: absolute;
    top: -177px;
    left: 0;
    z-index: -2;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    
    &::before {
      height: 160px;
      top: -160px;
    }
  }

  @media screen and (max-width: 576px) {
    &::before {
      height: 100px;
      top: -100px;
    }
  }
`

const ImageGradient = styled.div`
  background: rgb(0,0,0);
  background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
`

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const Card = styled.div`
  background-color: ${props => props.theme.color.face.light};
  border-radius: 5px;
  padding: 40px 40px 20px 40px;
  width: 100%;
  height: 100%;
  clip-path: polygon(100% 0, 100% 20%, 70% 100%, 0 100%, 0 0);
`

const CardBackground = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 5px;
`

const CardContainer = styled.div`
  position: absolute;
  width: 560px;
  height: auto;
  right: 0;
  bottom: -175px;
  z-index: 12;
  /* box-shadow: 0 0 8px rgba(0,0,0,0.3); */
  filter: drop-shadow(0 0 8px rgba(0,0,0,0.3));
  border-radius: 5px;

  @media screen and (max-width: 991px) {
    position: relative;
    bottom: unset;
    width: 100%;
    top: -20px;
  }
`

const StyledAbout = styled.div`
  padding-top: 80px;
  padding-bottom: 180px;
  margin-bottom: 5rem;

  @media screen and (max-width: 991px) {
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 0;
  }

  @media screen and (max-width: 576px) {
    padding-top: 30px;
    padding-bottom: 80px;
    margin-bottom: 0;
  }
`

const About = ({ fields }) => {
  return (
    <StyledAbout>
      <div className="d-flex justify-content-center">
        <ImageContainer>
          <StyledImage src={fields.image} />
          <ImageGradient />
        </ImageContainer>
      </div>

      <div className="position-relative container">
        <BackgroundText content={fields.background_text} />
        <CardContainer>
          <Card>
            <Content content={fields.description} />
          </Card>
          <CardBackground />
        </CardContainer>
      </div>
    </StyledAbout>
  )
}

export default About
