import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import HomeFilter from 'components/elements/Vacancies/HomeFilter'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h1 {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['50']};
    line-height: 61px;
    text-shadow: 0 0 6px rgba(0,0,0,1);

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size['36']};
      line-height: 37px;
    }

    @media screen and (max-width: 450px) {
      font-size: ${props => props.theme.font.size['26']};
      line-height: 27px;
    }

    @media screen and (max-width: 350px) {
      font-size: ${props => props.theme.font.size.xm};
      line-height: 21px;
    }
  }

  h2 {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['50']};
    line-height: 61px;
    text-shadow: 0 0 6px rgba(0,0,0,1);

    @media screen and (max-width: 991px) {
      font-size: ${props => props.theme.font.size['36']};
      line-height: 37px;
    }

    @media screen and (max-width: 450px) {
      font-size: ${props => props.theme.font.size['26']};
      line-height: 27px;
    }
  }
`

const StyledHomeBanner = styled.div`
  padding-top: 160px;
  padding-bottom: 200px;

  @media screen and (max-width: 1440px) {
    padding-bottom: 180px;
  }

  @media screen and (max-width: 1300px) {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 1200px) {
    padding-bottom: 70px;
  }

  @media screen and (max-width: 1150px) {
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1050px) {
    padding-bottom: 0px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 40px;
    padding-bottom: 140px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  @media screen and (max-width: 450px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 350px) {
    padding-top: 60px;
  }
`

const HomeBanner = ({ location, fields }) => {
  return (
    <StyledHomeBanner className="container">
      <Content content={fields.description} />
      <HomeFilter location={location} />
    </StyledHomeBanner>
  )
}

export default HomeBanner