/* eslint-disable react/no-array-index-key */
import React from 'react'

// Elements
import Image from 'components/shared/Image'

// Third Party
import styled from 'styled-components'


const Background = styled.div`
  width: 1440px;
  margin: 0 auto 0 auto;
  background-color: ${props => props.theme.color.face.main};
  padding: 60px 0 60px 0;
  position: relative;

  @media screen and (max-width: 1440px) {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    padding: 30px 0 30px 0;
  }
`

const StyledImage = styled(Image)`
  position: absolute !important;
  bottom: 0;
  right: 140px;
  height: auto;
  width: 360px;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const USPItem = styled.div`
  color: ${props => props.theme.color.text.light};
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size['18']};
  position: relative;

  padding: 20px 0 20px 80px;

  &::before {
    content: "";
    background-color: ${props => props.theme.color.face.contrast};
    width: 20px;
    height: 20px;
    position: absolute;
    top: 22px;
    left: 40px;
  }

  @media screen and (max-width: 576px) {
    padding: 10px 0 10px 80px;

    &::before {
      top: 16px;
    }
  }
`

const StyledUSP = styled.div`
  padding: 50px 0 100px 0;

  @media screen and (max-width: 991px) {
    padding: 15px 0 50px 0;
  }
`

const USP = ({ fields }) => {
  return (
    <StyledUSP>
      <Background>
        <StyledImage src={fields.image} />
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                {fields.usps.map((usp, index) => (
                  <USPItem key={index} className="col-6">
                    {usp.text}
                  </USPItem>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Background>
    </StyledUSP>
  )
}

export default USP
