import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Images
import SearchIcon from 'img/H_search.inline.svg'

// Third Party
import styled from 'styled-components'
import { useFlexSearch } from 'react-use-flexsearch'
import parse from 'html-react-parser'
import { motion } from 'framer-motion'


const SearchResult = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 25px 40px 25px 40px;
  border-radius: 5px;
  margin: 20px 0 20px 0;

  @media screen and (max-width: 576px) {
    padding: 20px 20px 20px 20px;
  }
`

const Type = styled.p`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: ${props => props.theme.color.face.contrast};
    height: 3px;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

const Row = styled.div`
  min-height: 200px;
`

const SearchResultContainer = styled.div``

const Content = styled(ParseContent)`
  h1 {
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['30']};
    line-height: 37px;
    margin-bottom: 0;
  }

  h2 {
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['30']};
    line-height: 37px;
  }
`

const ContentContainer = styled.div`
  max-width: 600px;
  padding: 20px 25px 20px 25px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.face.light};
  font-size: ${props => props.theme.font.size.sm};
  margin-bottom: 30px;

  @media screen and (max-width: 1200px) {
    box-shadow: 0 0 8px rgba(0,0,0,.3);
  }

  @media screen and (max-width: 991px) {
    max-width: unset;
    width: 100%;
  }
`

const StyledSearchBanner = styled.div`
  padding-top: 180px;
  padding-bottom: 20px;

  @media screen and (max-width: 1200px) {
    padding-top: 160px;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 40px;
  }
`

const NoResult = styled(motion.div)`
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  padding: 25px 40px 25px 40px;
  border-radius: 5px;
  margin: 20px 0 20px 0;
  background-color: ${props => props.theme.color.face.main};
  color: ${props => props.theme.color.text.light};

  h2 {
    font-size: ${props => props.theme.font.size['26']};
    font-weight: ${props => props.theme.font.weight.xl};
    margin-bottom: 0;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: ${props => props.theme.font.size['18']};
    }
  }

  @media screen and (max-width: 575px) {
    padding: 30px 20px 30px 20px;
  }
`

const StyledButtonAlt = styled(ButtonAlt)`
  a {
    padding: 1rem;
  }
`

const StyledInput = styled.input`
  margin: 15px 0 15px 0;
  border: none;
  outline: none;
  background-color: ${props => props.theme.color.face.input};
  padding-left: 15px;
  padding-right: 15px;
  height: 50px;
  width: 100%;

  &::placeholder {
    font-weight: ${props => props.theme.font.weight.l};
    font-size: ${props => props.theme.font.size['18']};
    color: rgba(0,0,0,.4);
  }
`

const SearchBanner = ({ fields }) => {
  const {
    vacancies,
    pages
  } = useStaticQuery(graphql`{
    vacancies: localSearchVacancies {
      index
      store
    }
    pages: localSearchPages {
      index
      store
    }
  }`)

  const [query, setQuery] = useState('')  

  const vacancyResults = useFlexSearch(
    query,
    vacancies.index,
    vacancies.store
  )

  const pageResults = useFlexSearch(
    query,
    pages.index,
    pages.store
  )

  const results = [...vacancyResults, ...pageResults]

  function handleSearchChange(e) {
    setQuery(e.target.value)
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = urlParams.get('query')

    setQuery(params)
  }, [])

  return (
    <StyledSearchBanner className="container">
      <ContentContainer>
        <Content content={fields.description} />
        <StyledInput
          onChange={handleSearchChange}
          placeholder="Zoeken..."
          value={query || ``}
        />
      </ContentContainer>

      <SearchResultContainer>
        {query !== null && (
          <Row className="row pb-2">
            {results.length === 0 ? (
              <>
                <div className="col-12">
                  <NoResult
                    initial={{ y: 50, opacity: 0.5 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      type: 'spring'
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-6 d-flex justify-content-start align-items-center">
                        <h2>Niet gevonden wat je zocht? Neem contact op met ons!</h2>
                      </div>
                      <div className="col-sm-6 d-flex justify-content-end align-items-center">
                        <ButtonAlt to="/contact">
                          Neem contact op
                        </ButtonAlt>
                      </div>
                    </div>
                    
                  </NoResult>
                </div>
              </>
            ) : (
              <>
                {results.map(r => (
                  <div key={r.id} className="col-sm-6">
                    <SearchResult
                      initial={{ y: 50, opacity: 0.5 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{
                        duration: 0.8,
                        type: 'spring'
                      }}
                    >
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="d-flex justify-content-start pb-1">
                            {r.pageType ? (
                              <Type className="float-left mb-0 font-weight-xl font-size-xm">{r.pageType}</Type>
                            ) : (
                              <Type className="float-left mb-0 font-weight-xl font-size-xm">{r.type}</Type>
                            )}
                          </div>
                          <h2 className="mb-0 text-left font-weight-xl">{parse(r.title)}</h2>
                        </div>
                        <div className="d-flex col-sm-7 pt-sm-0 pt-4 justify-content-end align-items-center">
                          <StyledButtonAlt to={r.path}>
                            <p className="mb-0">
                              <SearchIcon />
                            </p>
                          </StyledButtonAlt>
                        </div>
                      </div>
                    </SearchResult>
                  </div>
                ))}
              </>
            )}
          </Row>
        )}
      </SearchResultContainer>
      
    </StyledSearchBanner>
  )
}

export default SearchBanner