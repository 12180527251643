/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled, { css } from 'styled-components'


const StyledFilter = styled.div`
  
`

const FilterType = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.m} !important;
    font-weight: ${props => props.theme.font.weight.xl};
  }

  ul {
    padding: 0;
    margin-bottom: 0;
  }

  li {
    list-style: none;
    padding-left: 30px;
    font-size: ${props => props.theme.font.size.sm};
  }

  input {
    display: none;
  }
`

const Label = styled.label`
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    border-radius: 2px;
    width: 12px;
    height: 12px;
    left: -22px;
    top: 4px;

    ${props => props.selected ? css`
      border: 1px solid ${props.theme.color.face.main};
      background-color: ${props.theme.color.face.main};
    ` : css`
      border: 1px solid ${props.theme.color.face.border};
      background-color: ${props.theme.color.face.light};
    `}
  }
`

const Filter = ({ title, givenFilters, filter, setFilter }) => {
  return (
    <StyledFilter>
      <FilterType className="pt-3">
        <h2>{title}</h2>
        <ul>
          {givenFilters.map((branche, index) => (
            <li key={index}>
              <Label selected={filter && filter.find(f => parseInt(f, 0) === branche.wordpress_id)} htmlFor={branche.name}>{branche.name}</Label>
              <input selected={filter && filter.find(f => parseInt(f, 0) === branche.wordpress_id)} value={branche.wordpress_id} onChange={(e) => setFilter(e.target.value)} id={branche.name} type="checkbox" />
            </li>
          ))}
        </ul>
      </FilterType>
    </StyledFilter>
  )
}

export default Filter
