import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    line-height: 24px;
    padding-bottom: 10px;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    line-height: 27px;
    text-align: justify;
    padding: 0 60px 0 0;

    @media screen and (max-width: 991px) {
      padding: 0;
    }
  }

  li {
    font-size: ${props => props.theme.font.size.sm};
    line-height: 27px;
    list-style: none;
    position: relative;

    &::before {
      content: "";
      background-color: ${props => props.theme.color.face.main};
      width: 5px;
      height: 5px;
      position: absolute;
      left: -20px;
      top: 10px;
    }
  }
`

const Anchor = styled.div`
  position: absolute;
  top: 0px;
`

const StyledStory = styled.div`
  position: relative;
`

const Story = ({ fields }) => {
  return (
    <StyledStory className="container">
      <Anchor id="meer-informatie" />
      <Content content={fields.description} />
    </StyledStory>
  )
}

export default Story
