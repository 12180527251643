/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Link } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'


const Content = styled(ParseContent)`
  padding: 0 0 60px 0;

  h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['36']};
  }

  @media screen and (max-width: 991px) {
    padding: 0 0 30px 0;
  }
`

const SqaureContent = styled(ParseContent)`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3;

  p {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['30']};
    line-height: 37px;
    text-shadow: 0 0 6px rgba(0,0,0,1);
  }

  @media screen and (max-width: 484px) {
    p {
      font-size: ${props => props.theme.font.size.l};
      line-height: 21px;
    }
  }

  @media screen and (max-width: 340px) {
    p {
      font-size: ${props => props.theme.font.size['18']};
      line-height: 18px;
    }
  }
`

const StyledImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
`

const SqaureContainer = styled(motion.div)`
  border-radius: 10px;
  position: relative;
  height: 350px;
  width: 350px;
  overflow: hidden;

  @media screen and (max-width: 1440px) {
    height: 300px;
    width: 300px;
  }

  @media screen and (max-width: 1200px) {
    height: 280px;
    width: 280px;
    margin: 14px 0 14px 0;
  }

  @media screen and (max-width: 991px) {
    height: 280px;
    width: 280px;
  }

  @media screen and (max-width: 430px) {
    height: 250px;
    width: 250px;
  }

`

const SqaureShadow = styled(motion.div)`
  position: absolute;
  z-index: 2;
  top: 30%;
  right: -93%;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.color.face.contrast};
`

const StyledSqaure = styled(motion.div)`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
`

const SqaureWrapper = styled.div`
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
`

const StyledBranchesSqaures = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 576px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const ImageContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
`

const BranchesSqaures = ({ fields }) => {
  return (
    <StyledBranchesSqaures className="container">
      <Content content={fields.description} />
      <div className="row">
        {fields.sqaures.map((sqaure, index) => (
          <SqaureWrapper left={index % 2 === 0} key={index} className="col-12 col-md-6 col-lg-4">
            <Link to={sqaure.link.url}>
              <SqaureContainer
                initial="init"
                whileHover="hover"
              >
                <StyledSqaure>
                  <ImageContainer
                    variants={{
                      init: { backgroundColor: 'rgba(0,0,0,0)' },
                      hover: { backgroundColor: 'rgba(0,0,0,0.2)' }
                    }}
                  >
                    <StyledImage src={sqaure.image} />
                  </ImageContainer>
 
                  <SqaureContent content={sqaure.description} />
                </StyledSqaure>
                <SqaureShadow
                  variants={{
                    init: { x: 30, rotate: 0 },
                    hover: { x: 0, rotate: 20 }
                  }}
                />
              </SqaureContainer>
              
            </Link>
          </SqaureWrapper>
        ))}
      </div>
    </StyledBranchesSqaures>
  )
}

export default BranchesSqaures
