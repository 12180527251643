/* eslint-disable no-underscore-dangle */
import React from 'react'

// Elements
import BannerShell from 'components/elements/Banner/BannerShell'
import About from 'components/elements/About'
import BranchesSqaures from 'components/elements/BranchesSqaures'
import Clients from 'components/elements/Clients'
import Text from 'components/elements/Text'
import USP from 'components/elements/USP'
import Werkwijze from 'components/elements/Werkwijze'
import ColumnContent from 'components/elements/ColumnContent'
import VacanciesFilter from 'components/elements/Vacancies/VacanciesFilter'

const FlexContentHandler = ({ path, location, fields, wpId, pageContext }) => {
  return (
    <>
      {fields && fields.map((content, index) => (
      // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {{
            WordPressAcf_banner: <BannerShell pageContext={pageContext} location={location} wpId={wpId} id="banner" fields={content} />,
            WordPressAcf_branches_sqaures: <BranchesSqaures id="branches_sqaures" fields={content} />,
            WordPressAcf_werkwijze: <Werkwijze id="werkwijze" fields={content} />,
            WordPressAcf_about: <About id="about" fields={content} />,
            // WordPressAcf_clients: <Clients id="clients" fields={content} />,
            WordPressAcf_text: <Text id="text" fields={content} />,
            WordPressAcf_usp: <USP id="usp" fields={content} />,
            WordPressAcf_column_content: <ColumnContent wpId={wpId} id="column_content" fields={content} path={path} />,
            WordPressAcf_vacancies_filter: <VacanciesFilter location={location} id="vacancies_filter" fields={content} />
          }[content.internal.type]}
        </React.Fragment>
      ))}
    </>
  )
}


export default FlexContentHandler