import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
  }

  td {
    font-weight: ${props => props.theme.font.weight.l};
    font-size: ${props => props.theme.font.size.sm};
    padding: 4px 0 4px 0;

    @media (max-width: 991px) {
      height: auto !important;
    }
  }

  table {
    width: 100%;
  }

  @media screen and (max-width: 768px) {


    tbody:first-of-type {
      & > tr {
        @media (max-width: 991px) {
          height: auto !important;
        }

        display: flex;
        flex-wrap: wrap;

        & > td {
          width: 100% !important;

          & > table > tbody > tr > td:first-of-type {
            width: 10% !important;
            display: flex;
            align-items: center;
            img {
              margin: auto;
            }

            @media (min-width: 992px) {
              height: 50px;
            }

            @media (max-width: 991px) {
              height: auto !important;
            }
          }

          & > table > tbody > tr > td:last-of-type {
            width: 90% !important;
            padding-left: 15px;
            display: flex;
            align-items: center;

            @media (min-width: 992px) {
              height: 50px;
            }

            @media (max-width: 991px) {
              height: auto !important;
            }
          }
        }  
      }
    }
  }
`

const StyledShortInfo = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 95%;
    height: 1px;
    bottom: 50px;
    left: 0;
    background-color: ${props => props.theme.color.face.main};

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
`

const ShortInfo = ({ fields }) => {
  return (
    <StyledShortInfo className="container">
      <Content content={fields.description} />
    </StyledShortInfo>
  )
}

export default ShortInfo
