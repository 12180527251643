/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonAlt from 'components/elements/Button/ButtonAlt'
import ButtonDefault from 'components/elements/Button/ButtonDefault'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h1 {
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.xl};
    margin-bottom: 0;

    @media (min-width: 992px) {
      font-size: ${props => props.theme.font.size['30']};
      line-height: 37px;
    }

    @media (max-width: 991px) {
      font-size: ${props => props.theme.font.size.l};
      line-height: 30px;
    }

    @media (max-width: 575px) {
      font-size: ${props => props.theme.font.size.xm};
      line-height: 24px;
    }
  }

  h2 {
    color: ${props => props.theme.color.text.main};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['30']};
    line-height: 37px;
  }
`

const ContentContainer = styled.div`
  max-width: 600px;
  padding: 20px 25px 20px 25px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.face.light};
  font-size: ${props => props.theme.font.size.sm};

  @media screen and (max-width: 1200px) {
    box-shadow: 0 0 8px rgba(0,0,0,.3);
  }
`

const ButtonContainer = styled.div`
  padding: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 991px){
    > div {
      width: 100%;
    }
  }
`

const StyledButtonAlt = styled(ButtonAlt)`
  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  cursor: pointer;
`

const StyledButtonDefault = styled(ButtonDefault)`
  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: center;
  }

  cursor: pointer;
`

const StyledVacatureBanner = styled.div`
  padding-top: 180px;
  padding-bottom: 20px;

  @media screen and (max-width: 1200px) {
    padding-top: 160px;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 576px) {
    padding-top: 40px;
  }
`

const VacatureBanner = ({ fields, wpId }) => {
  const {
    vacancies: {
      nodes: vacancies
    }
  } = useStaticQuery(graphql`{
    vacancies: allWordpressWpVacature {
      nodes {
        title
        wordpress_id
        locatie {
          name
        }
        uren {
          name
        }
        branche {
          name
        }
      }
      
    }
  }`)

  const vacancy = vacancies.filter(v => v.wordpress_id === wpId)[0]

  const handleScroll = (id) => {
    if (typeof document !== 'undefined') {
      document.querySelector(`#${id}`).scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <StyledVacatureBanner className="container">
      <ContentContainer>
        <Content content={fields.description} />
        <div className="pt-1 mb-0">{`${vacancy.locatie[0].name} | ${vacancy.uren[0].name} | ${vacancy.branche[0].name}`}</div>
        <ButtonContainer>
          <div onClick={() => handleScroll('solliciteer-direct')}>
            <StyledButtonAlt className="px-3 mr-4">
              Solliciteer direct!
            </StyledButtonAlt>
          </div>
          <div onClick={() => handleScroll('meer-informatie')}>
            <StyledButtonDefault className="px-3">
              Meer informatie
            </StyledButtonDefault>
          </div>
        </ButtonContainer>
      </ContentContainer>
    </StyledVacatureBanner>
  )
}

export default VacatureBanner