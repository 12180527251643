import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size['50']};
    font-weight: ${props => props.theme.font.weight.xl};
    padding: 0 0 60px 0;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    line-height: 27px;
  }

  @media screen and (max-width: 991px) {
    h2 {
      line-height: 51px;
      padding: 0 0 30px 0;
    }
  }

  @media screen and (max-width: 576px) {
    h2 {
      font-size: ${props => props.theme.font.size['30']};
      line-height: 30px;
    }
  }
`

const StyledText = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 991px) {
    padding-top: 0px;
    padding-bottom: 15px;
  }
`

const Text = ({ fields }) => {
  return (
    <StyledText className="container">
      <div className="row">
        <div className="col-lg-7">
          <Content content={fields.description} />
        </div>
      </div>
    </StyledText>
  )
}

export default Text
