import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'
import ButtonOutline from 'components/elements/Button/ButtonOutline'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size['30']};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const StyledApply = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  border-radius: 5px;
  padding: 15px 30px 15px 30px;
  max-width: 410px;
`

const StyledButtonOutline = styled(ButtonOutline)`
  width: calc(100% + 12px);
  text-align: center;
  margin: 0 -6px 0 -6px;
`

const Spacer = styled.div`
  padding-top: 15px;
  font-weight: ${props => props.theme.font.weight.l};
`

const Anchor = styled.div`
  position: absolute;
  top: 0px;
`

const Apply = ({ fields, wpId }) => {
  const {
    vacancies: {
      nodes: vacancies
    }
  } = useStaticQuery(graphql`{
    vacancies: allWordpressWpVacature {
      nodes {
        wordpress_id
        title
      }
    }
  }`)

  const vacancy = vacancies.filter(v => v.wordpress_id === wpId)[0]

  return (
    <StyledApply className="container my-5">
      <Anchor id="solliciteer-direct" />
      <Content className="pb-2" content={fields.description} />
      <a href={`whatsapp://wa.me/0682971693?text=Ik zou graag willen solliciteren voor ${vacancy.title}, kunnen wij een afspraak inplannen om hierover in gesprek te gaan?`}>
        <StyledButtonOutline>
          Solliciteer via WhatsApp
        </StyledButtonOutline>
      </a>
      <Spacer className="d-flex justify-content-center">
        Of
      </Spacer>
      <GravityForm id={2} title={vacancy.title} apply />
    </StyledApply>
  )
}

export default Apply
