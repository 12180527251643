import React from 'react'

// Third Party
import styled from 'styled-components'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'


const StyledBreadCrumb = styled.div`
  position: absolute;

  nav {
    background-color: ${props => props.theme.color.face.light};
    padding: 0;
    margin: 0;

    ol {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;

      li {
        padding-right: 10px;
        padding-left: 10px;
        font-size: ${props => props.theme.font.size.sm};

        &:first-of-type {
          padding-left: 0;
        }

        .breadcrumb {
          &__link {
            &__active {
              font-weight: ${props => props.theme.font.weight.l};
              color: ${props => props.theme.color.text.contrast};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-bottom: 20px;
  }

`

const StyledBreadcrumb = styled(Breadcrumb)``

const BreadCrumb = ({ location, pageContext }) => {
  const { breadcrumb: { crumbs } } = pageContext

  const capitalize = string => {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const customCrumbLabel = capitalize(location.pathname.toLowerCase().replace('-', ' ').split('/').join(''))

  return (
    <StyledBreadCrumb>
      <StyledBreadcrumb
        crumbs={crumbs}
        crumbSeparator=" | "
        crumbLabel={customCrumbLabel}
      />
    </StyledBreadCrumb>
  )
}

export default BreadCrumb
