/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react'

// Third Party
import styled, { css } from 'styled-components'
import TextInput from 'react-autocomplete-input'


const StyledAutocompleteFilter = styled.div`
  padding: 25px 0 20px 0;
`

const StyledInput = styled(TextInput)`
  border: none;
  outline: none;
  background-color: ${props => props.theme.color.face.input};
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding: 15px 15px 15px 15px;

  font-size: ${props => props.theme.font.size.sm};

  &:focus {
    font-size: 16px;
  }
`

const InputContainer = styled.div`
  position: relative;

  ul {
    position: absolute;
    left: 0 !important;
    background-color: ${props => props.theme.color.face.light};
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    border-radius: 5px;
    padding: 5px 0px 5px 0;;
    z-index: 10;

    li {
      list-style: none;
      cursor: pointer;
      padding: 3px 15px 3px 15px;
      &:hover {
        background-color: ${props => props.theme.color.face.main};
        color: ${props => props.theme.color.text.light};
      }
    }
  }
`

const Placeholder = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.grey};
  pointer-events: none;

  ${props => props.show ? css`
    display: block;
  ` : css`
    display: none;
  `}

  span {
    font-size: ${props => props.theme.font.size.s};
  
    b {
      font-weight: ${props => props.theme.font.weight.m};
      color: ${props => props.theme.color.text.contrast};
      pointer-events: all;
      cursor: pointer;
    }
  }
`

const AutocompleteFilter = ({ filters, givenFilters, setFilters }) => {
  const [value, setValue] = useState('')
  const [listValues, setListValues] = useState([])

  useEffect(() => {
    const copyList = []

    givenFilters.forEach(filter => {
      filter.list.forEach(list => {
        copyList.push(list.name)
      })
    })

    setListValues(copyList)
  }, [])

  const handleAutocomplete = event => {
    const searchList = event.replace(' ', '').split(',')

    setTimeout(() => {
      searchList.forEach(search => {
        givenFilters.forEach(filter => {
          filter.list.forEach(list => {
            if (list.name === search) {
              if (filters.filter(f => f.type === filter.type)[0].list.find(l => parseInt(l, 0) === list.wordpress_id)) {
                // Empty
              } else {
                setFilters.filter(sf => sf.type === filter.type)[0].function(`${list.wordpress_id}`)
  
                // Overwrite event to be empty
                setValue('')
              }
            }
          })
        })
      })
    }, 300)

    setValue(event)
  }

  return (
    <StyledAutocompleteFilter>
      <InputContainer>
        <StyledInput
          onChange={(e) => handleAutocomplete(e)}
          value={value}
          options={listValues}
          Component="input"
          matchAny
          offsetY={25}
          trigger=""
          spacer=","
        />
        <Placeholder show={value === null || value === ''}>
          Zoekterm
          <span>
            {` (bijv. `} 
            <b onClick={() => handleAutocomplete('Installatiemonteur')}>
              Installatiemonteur
            </b>
            )
          </span>
        </Placeholder>
      </InputContainer>
      
    </StyledAutocompleteFilter>
  )
}

export default AutocompleteFilter
