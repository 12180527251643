import React from 'react'
import { navigate } from 'gatsby'

// Images
import Close from 'img/H_close.inline.svg'

// Components
import GravityForm from 'components/shared/GravityForm'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'


const StyledOpenSollicitatie = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
`

const InnerModal = styled(motion.div)`
  background-color: ${props => props.theme.color.face.contrast};
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  border-radius: 15px;
  width: 800px;
  height: fit-content;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 30px 40px 20px 40px;

  @media screen and (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
`

const FormTitle = styled.h2`
  padding-bottom: 20px;
  font-weight: ${props => props.theme.font.weight.xl};
`

const StyledClose = styled(Close)`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`

const OpenSollicitatie = () => {
  return (
    <StyledOpenSollicitatie
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={(e) => {navigate('?'); e.stopPropagation()}}
    >
      <InnerModal
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 100, opacity: 0 }}
        onClick={(e) => e.stopPropagation()}
      >
        <StyledClose onClick={() => navigate('?')} alt="" />
        <div>
          <FormTitle>OPEN SOLLICITATIE</FormTitle>
          <GravityForm open id={3} />
        </div>
      </InnerModal>
    </StyledOpenSollicitatie>
  )
}

export default OpenSollicitatie
