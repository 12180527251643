/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

// Elements
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Images
import Search from 'img/H_search.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import Select from 'react-select'
import TextInput from 'react-autocomplete-input'
import { motion } from 'framer-motion'


const StyledHomeFilter = styled.div`
  margin-top: 100px;
  background-color: ${props => props.theme.color.face.light};
  border-radius: 5px;
  width: 700px;
  height: 150px;
  padding: 30px 30px 30px 30px;

  h2 {
    font-size: ${props => props.theme.font.size['18']};
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 15px;

    @media screen and (max-width: 430px) {
      font-size: ${props => props.theme.font.size.m};
    }

    @media screen and (max-width: 420px) {
      font-size: ${props => props.theme.font.size.sm};
    }

    @media screen and (max-width: 350px) {
      padding-bottom: 5px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    margin-top: 30px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 15px;
  }

  @media screen and (max-width: 350px) {
    margin-top: 10px;
    padding: 15px;
  }
`

const StyledSelect = styled(Select)`
  border: none;
  outline: none;
  border-radius: 5px;
  width: 245px;
  height: 50px;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.grey};

  &::placeholder {
    font-size: ${props => props.theme.font.size.sm};
  }

  .styled-select {
    &__control {
      height: 100%;
      border: none;
      outline: none;
      background-color: ${props => props.theme.color.face.input};
      box-shadow: none;
      padding: 0 0 0 10px;
    }

    &__menu-list {
      box-shadow: 0 0 8px rgba(0,0,0,.3);
    }

    &__option {
      cursor: pointer;
      font-weight: ${props => props.theme.font.weight.l};
      color: ${props => props.theme.color.text.main};

      &--is-selected {
        background-color: ${props => props.theme.color.face.contrast};
        color: ${props => props.theme.color.text.light};
      }

      &--is-focused {
        background-color: ${props => props.theme.color.face.input};
        color: ${props => props.theme.color.text.contrast};
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const StyledButtonAlt = styled(ButtonAlt)`
  cursor: pointer;

  a {
    padding: 11px 11px 11px 11px;
  }
`

const Placeholder = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: ${props => props.theme.font.size.sm};
  color: ${props => props.theme.color.text.grey};
  pointer-events: none;

  ${props => props.show ? css`
    display: block;
  ` : css`
    display: none;
  `}

  span {
    font-size: ${props => props.theme.font.size.s};
  
    b {
      font-weight: ${props => props.theme.font.weight.m};
      color: ${props => props.theme.color.text.contrast};
      pointer-events: all;
      cursor: pointer;
    }
  }
`

const InputWrapper = styled.div`
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`

const InputContainer = styled.div`
  position: relative;

  ul {
    position: absolute;
    left: 0 !important;
    background-color: ${props => props.theme.color.face.light};
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    border-radius: 5px;
    padding: 5px 0px 5px 0;;
    z-index: 10;

    li {
      list-style: none;
      cursor: pointer;
      padding: 3px 15px 3px 15px;
      &:hover {
        background-color: ${props => props.theme.color.face.main};
        color: ${props => props.theme.color.text.light};
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const StyledInput = styled(TextInput)`
  background-color: ${props => props.theme.color.face.input};
  border: none;
  outline: none;
  padding: 10px 15px 10px 15px;
  border-radius: 5px;
  width: 313px;
  height: 50px;
  font-size: ${props => props.theme.font.size.sm};

  &::placeholder {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.grey};
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const HomeFilter = () => {
  const [values, setValues] = useState({
    query: '',
    branche: '',
  })
  const [options, setOptions] = useState([])
  const {
    branches: {
      nodes: branches
    },
    functies: {
      nodes: functies
    }
  } = useStaticQuery(graphql`{
    branches: allWordpressWpBranche {
      nodes {
        name
        wordpress_id
      }
    }
    functies: allWordpressWpFunctie {
      nodes {
        name
        wordpress_id
      }
    }
  }`)

  const selectOptions = []

  branches.forEach(branche => {
    selectOptions.push({ value: branche.wordpress_id, label: branche.name })
  })

  useEffect(() => {
    const copyList = []

    functies.forEach(functie => {
      copyList.push(functie.name)
    })

    setOptions(copyList)
  }, [])

  const handleAutocomplete = event => {
    setValues({
      query: event,
      branche: values.branche
    })
  }

  const handleSelectChange = event => {
    setValues({
      query: values.query,
      branche: event.value
    })
  }

  const handleSearch = () => {
    navigate(`/vacatures/?${values.query !== '' ? `query=${values.query.replace(' ', '')}&` : ``}${values.branche !== '' ? `branche=${values.branche}` : ``}`)
  }

  return (
    <StyledHomeFilter>
      <h2>Vind de vacature die bij jouw past</h2>
      <InputWrapper className="d-flex justify-content-between">
        <InputContainer>
          <Placeholder show={values.query === null || values.query === ''}>
            Zoekterm
            <span>
              {` (bijv. `} 
              <b onClick={() => setValues({ query: 'Installatiemonteur', branche: values.branche })}>
                Installatiemonteur
              </b>
              )
            </span>
          </Placeholder>
          <StyledInput
            className="mr-md-3 mb-lg-0 mb-3"
            onChange={(e) => handleAutocomplete(e)}
            value={values.query}
            options={options}
            Component="input"
            matchAny
            offsetY={25}
            trigger=""
            spacer=","
          />
        </InputContainer>
        <div className="d-flex justify-content-between w-100">
          <StyledSelect
            isSearchable
            placeholder="Branche"
            classNamePrefix="styled-select"
            className="mr-3 mb-md-0 mb-sm-3"
            options={selectOptions}
            onChange={event => handleSelectChange(event)}
          />
          <motion.div
            initial={{ opacity: .7 }}
            whileHover={{ opacity: 1, rotate: [0, 5, -10, 0] }}
            transition={{ duration: .4 }}
            role="button"
            tabIndex="-2"
            onKeyDown={() => handleSearch()}
            onClick={() => handleSearch()}
          >
            <StyledButtonAlt>
              <Search />
            </StyledButtonAlt>
          </motion.div>
        </div>
        
      </InputWrapper>
    </StyledHomeFilter>
  )
}

export default HomeFilter
