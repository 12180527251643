import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h1 {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['50']};
    line-height: 61px;
    text-shadow: 0 0 6px rgba(0,0,0,1);
  }

  h2 {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['50']};
    line-height: 61px;
    text-shadow: 0 0 6px rgba(0,0,0,1);
  }

  @media screen and (max-width: 576px) {
    h1 {
      font-size: ${props => props.theme.font.size['36']};
      line-height: 45px;
    }

    h2 {
      font-size: ${props => props.theme.font.size['36']};
      line-height: 45px;
    }
  }
`
const StyledStandardBanner = styled.div`
  padding: 220px 0 80px 0;

  @media screen and (max-width: 1300px) {
    padding: 180px 0 80px 0;
  }

  @media screen and (max-width: 1200px) {
    padding: 160px 0 80px 0;
  }

  @media screen and (max-width: 1100px) {
    padding: 140px 0 80px 0;
  }

  @media screen and (max-width: 1050px) {
    padding: 120px 0 80px 0;
  }

  @media screen and (max-width: 991px) {
    padding: 80px 15px 80px 15px;
  }

  @media screen and (max-width: 900px) {
    padding: 60px 15px 80px 15px;
  }

  @media screen and (max-width: 810px) {
    padding: 20px 15px 80px 15px;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px 80px 15px;
  }

  @media screen and (max-width: 430px) {
    padding: 15px 15px 40px 15px;
  }

  @media screen and (max-width: 380px) {
    padding: 15px 15px 30px 15px;
  }
`

const StandardBanner = ({ fields }) => {
  return (
    <StyledStandardBanner className="container">
      <Content content={fields.description} />
    </StyledStandardBanner>
  )
}

export default StandardBanner