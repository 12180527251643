/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled from 'styled-components'
import ContactForm from './ContactForm'
import ContactInformation from './ContactInformation'
import CtaQuestion from './CtaQuestion'
import Story from './Story'
import Share from './Share'
import Apply from './Vacancies/Apply'
import ShortInfo from './Vacancies/ShortInfo'


const StyledColumnContent = styled.div`
  padding: 0px 0 80px 0;
`

const ColumnContent = ({ fields, wpId, path }) => {
  return (
    <StyledColumnContent>
      <div className="container">
        <div className="row">
          {fields.row.map((column, index) => (
            <div key={index} className={`col-lg-${column.width}`}>
              {column.content.map((content) => (
                <>
                  {{
                    contact_information: <ContactInformation key="contact_information" fields={content} />,
                    contact_form: <ContactForm key="contact_form" fields={content} />,
                    story: <Story key="story" fields={content} />,
                    vacancy_share: <Share key="vacancy_share" fields={content} path={path} />,
                    cta_question: <CtaQuestion key="cta_question" fields={content} />,
                    apply: <Apply wpId={wpId} key="apply" fields={content} />,
                    short_info: <ShortInfo key="short_info" fields={content} />
                  }[content.acf_fc_layout]}
                </>
              ))}
            </div>
          ))}
        </div>
      </div>
    </StyledColumnContent>
  )
}

export default ColumnContent
