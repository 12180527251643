import React from 'react'

// Images
import Pin from 'img/H_pin.inline.svg'
import Phone from 'img/H_phone.inline.svg'
import Mail from 'img/H_mail.inline.svg'

// Third Party
import styled from 'styled-components'


const StyledContactInformation = styled.div`
  background-color: ${props => props.theme.color.face.main};
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  padding: 30px 25px 0 25px;
  color: ${props => props.theme.color.text.light};
  max-width: 300px;

  h2 {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.l};
    font-weight: ${props => props.theme.font.weight.xl};
    line-height: 29px;
  }

  p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
    margin-bottom: 0;
  }

  a {
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: none;

    &:hover {
      p {
        color: ${props => props.theme.color.text.contrast} !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 576px) {
    margin-left: unset;
    margin-right: unset;
    max-width: unset;
    /* padding: 30px 25px 50px 25px; */
  }
`

const Map = styled.div`
  width: 300px;
  height: 199px;
  border-radius: 5px;
  position: relative;
  margin-top: 40px;

  iframe {
    position: absolute;
    bottom: 0;
    left: -25px;
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% + 50px);
  }

  @media screen and (max-width: 991px) {
    width: calc(100% + 50px);

    iframe {
      left: -25px;
      bottom: 0px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const IconContainer = styled.div`
  width: 40px;
`

const Info = styled.div`
  padding: 0 0 0 20px;
`

const ContactInformation = ({ fields }) => {
  return (
    <StyledContactInformation>
      <h2 className="pb-4">Contactgegevens</h2>
      
      <div className="d-flex pb-4 align-items-center justify-content-start">
        <IconContainer className="d-flex justify-content-center align-items-center">
          <Pin />
        </IconContainer>
        <Info>
          <p>{fields.address}</p>
          <p>{fields.zipcode}</p>
        </Info>
      </div>

      <div className="d-flex pb-4 align-items-center justify-content-start">
        <IconContainer className="d-flex justify-content-center align-items-center">
          <Phone />
        </IconContainer>
        <Info>
          <a href={fields.phonenumber.url}><p>{fields.phonenumber.title}</p></a>
        </Info>
      </div>

      <div className="d-flex align-items-center justify-content-start">
        <IconContainer className="d-flex justify-content-center align-items-center">
          <Mail />
        </IconContainer>
        <Info>
          <a href={fields.email.url}><p>{fields.email.title}</p></a>
        </Info>
      </div>

      <Map>
        <iframe
          title="google-maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2444.1166005468544!2d5.137631115754107!3d52.22309936594215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c66c940871f383%3A0x15a53df5ddc783e!2sNieuwe%20Havenweg%2010%2C%201216%20BS%20Hilversum!5e0!3m2!1snl!2snl!4v1611152964505!5m2!1snl!2snl"
          width="100%"
          height="199"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
        />
      </Map>
    </StyledContactInformation>
  )
}

export default ContactInformation
