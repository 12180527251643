/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { v4 } from 'uuid'

// Libraries
import Select from 'react-select'

// Components
import ButtonDefault from 'components/elements/Button/ButtonDefault'
import Content from 'components/shared/Content'
import Loading from 'components/shared/Loading'

// Images
import Upload from 'img/H_upload.inline.svg'

const StyledForm = styled.form`
  position: relative;

  .hidden {
    display: none;
  }
`

const ConfirmationMessage = styled(Content)`
  font-size: ${props => props.theme.font.size.l};
  font-weight: ${props => props.theme.font.weight.xl};
  text-align: center;
  

  ${props => props.apply ? css`
    padding: 15px 50px 15px 50px;
    color: ${props.theme.color.text.main};
  ` : css`
    padding: 100px 50px 0 50px;
    color: ${props.theme.color.text.light};
  `}
`

const Label = styled.label`
  display: block;
`

const Mandatory = styled.span`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.face.contrast};
  margin-left: 5px;
`

const GravityForm = ({ title, apply, className, id, open }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === id)[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === "submit").length === 0)  {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: "submit",
          text: tempForm.button.text
        }
      ]
    }

    if (apply) {
      setFields({
        'input_7': title
      })
    }

    setForm({
      ...form,
      ...tempForm
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      })

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
        console.log(response)
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let {value} = event.target || event.value

    if (event.target) {
      if (event.target.type === "checkbox") {
        value = event.target.checked ? event.target.value : ""
      }

      setFields({
        ...fields,
        [event.target.name]: value
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value
      })
    }
  }

  if (status === "done") {
    return <ConfirmationMessage apply={apply} content={message} />
  }

  if (form.formFields) {
    return (
      <StyledForm id={`form_${gfForm.formId}`} className={`row ${className ? `${className}` : ``}`} method="post" onSubmit={handleOnSubmit}>
        {status === "processing" && (
          <Loading />
        )}
        {form.formFields && form.formFields.map((field, key) => {

          if (field.type === 'textarea' || field.type === 'submit' || field.type === 'fileupload') {
            return (
              <div key={key} className="col-12 px-2">
                <FormField open={open} apply={apply} field={field} fields={fields} onChange={handleFieldChange} />
              </div>
            )
          }

          return (
            <div key={key} className={`${apply ? `col-12` : `${(field.type === 'phone' || field.type === 'email') ? `col-sm-6` : `col-6`}`} px-2`}>
              <FormField field={field} fields={fields} onChange={handleFieldChange} />
            </div>
          )
        })}
      </StyledForm>
    )
  }

  console.error("No gravity forms found with id", id)
  return false;
}

const StyledTextField = styled.div`
  input {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 100%;

    font-weight: ${props => props.theme.font.weight.m};
    font-size: ${props => props.theme.font.size.sm};
  }
`

const TextField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextField className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextField>
)

const StyledEmailField = styled.div`
  input {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 100%;

    font-weight: ${props => props.theme.font.weight.m};
    font-size: ${props => props.theme.font.size.sm};
  }
`

const EmailField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledEmailField className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledEmailField>
)

const StyledPhoneField = styled.div`
  input {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 100%;

    font-weight: ${props => props.theme.font.weight.m};
    font-size: ${props => props.theme.font.size.sm};
  }
`

const PhoneField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledPhoneField className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledPhoneField>
)

const StyledTextAreaField = styled.div`
  textarea {
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 10px 20px 10px 20px;
    width: 100%;
    min-height: 142px;

    font-weight: ${props => props.theme.font.weight.m};
    font-size: ${props => props.theme.font.size.sm};
  }
`

const TextAreaField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextAreaField className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextAreaField>
)

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices);

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div`

`

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  
  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach(choice => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`
    })
  })

  // Custom Select component
  const MySelect = props => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect 
        options={options}
        onChange={onChange}
        value={options.filter(option => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const StyledButtonDefault = styled(ButtonDefault)`
  ${props => (props.apply || props.open) && css`
    width: 100%;
    text-align: center;
    margin: 15px 0 15px 0;
  `}
`

const SubmitButton = ({ open, apply, field }) => (
  <div>
    <StyledButtonDefault open={open} apply={apply} isCustom>
      <button type="submit">
        {field.text}
      </button>
    </StyledButtonDefault>
  </div>
)

const StyledFileInput = styled.div`
  input {
    display: none;
  }

  label {
    border: 1px solid ${props => props.theme.color.face.light};
    border-radius: 5px;
    padding: 12px 20px 8px 20px;
    height: 50px;
    width: 100%;
    color: ${props => props.theme.color.text.light};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }
`

const UploadFiles = styled.div`
  color: ${props => props.theme.color.text.light};
  padding-left: 30px;
  padding-bottom: 15px;
`

const FileField = ({ value, onChange, field: { id, type, allowedExtensions, cssClass, placeholder } }) => {

  function handleChange(file) {
      onChange({
        target: {
          name: `input_${id}`,
          value: file
        }
      })
  }

  function handleFileUpload(e) {
    const { target: { files } } = e;

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();
    console.log(e);

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i]);
      }
    }
  }

  return (
    <StyledFileInput className="form-upload" onDrop={handleFileDrop} onDragOver={e => e.preventDefault()}>
      <Label htmlFor={`input_${id}`}>
        CV uploaden
        <Upload />
      </Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
      {value && (
        <UploadFiles>
          {value.name}
        </UploadFiles>
      )}
    </StyledFileInput>
  )
}

export const FormField = ({ field, fields, onChange, apply, open }) => (
  <>
    {field.type === "text" && <TextField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "email" && <EmailField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "phone" && <PhoneField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "textarea" && <TextAreaField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "checkbox" && <CheckboxField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "select" && <SelectField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "fileupload" && <FileField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "submit" && <SubmitButton open={open} apply={apply} field={field} />}
  </>
)

export default GravityForm


