import React from 'react'

// Elements
import Image from 'components/shared/Image'
import HomeBanner from 'components/elements/Banner/HomeBanner'
import StandardBanner from 'components/elements/Banner/StandardBanner'
import SearchBanner from 'components/elements/Banner/SearchBanner'
import VacatureBanner from 'components/elements/Banner/VacatureBanner'
import BreadCrumb from 'components/elements/BreadCrumb'

// Third Party
import styled, { css } from 'styled-components'


const StyledBanner = styled.div``

const StyledImage = styled(Image)`
  /* width: 1440px; */
  border-bottom-right-radius: 81px;
  border-bottom-left-radius: 81px;
  width: 100%;
  position: absolute !important;
  top: 0;
  z-index: -1;

  ${props => props.type === 'home' ? css`
    max-height: 900px;
  ` : css`
    max-height: 525px;
  `}

  @media screen and (max-width: 1440px) {
    width: 100vw;
  }

  @media screen and (max-width: 768px) {
    border-bottom-right-radius: 41px;
    border-bottom-left-radius: 41px;
  }
`

const BannerShell = ({ location, className, fields, wpId, pageContext }) => {
  return (
    <StyledBanner className={`${className ? `${className}` : ``}`}>
      <div className="d-flex justify-content-center">
        <StyledImage type={fields.style} src={fields.image} />
      </div>
      
      {{
        'home': <HomeBanner location={location} fields={fields} />,
        'standard': <StandardBanner location={location} fields={fields} />,
        'search': <SearchBanner location={location} fields={fields} />,
        'vacancy-detail': <VacatureBanner pageContext={pageContext} wpId={wpId} fields={fields} />
      }[fields.style]}

      {(wpId === 177 || wpId === 11) && (
        <div className="container">
          <BreadCrumb location={location} pageContext={pageContext} />
        </div>
      )}
      
    </StyledBanner>
  )
}

export default BannerShell
