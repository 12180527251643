import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 180px;
  z-index: 20;

  @media screen and (max-width: 768px) {
    height: 120px;
  }

  @media screen and (max-width: 430px) {
    height: 80px;
  }
`

const HeaderBackground = styled.div`
  @media screen and (max-width: 1440px) {
    display: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    /* left: -150px; */
    /* width: 1440px; */
    width: 100%;
    height: 120px;
    background-color: ${props => props.theme.color.face.main};
    display: block;
  }

  &::before {
    content: "";
    position: absolute;
    top: 120px;
    /* left: -150px;
    width: 1440px; */
    width: 100%;
    height: 60px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
    display: block;
  }
`

const SmallHeaderBackground = styled.div`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -150px;
    width: 1440px;
    height: 120px;
    background-color: ${props => props.theme.color.face.main};
    display: none;

    @media screen and (max-width: 1440px) {
      width: 100vw;
      left: 0;
      display: block;
    }

    @media screen and (max-width: 991px) {
      height: 60px;
    }

    @media screen and (max-width: 576px) {
      height: 30px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 120px;
    left: -150px;
    width: 1440px;
    height: 60px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
    display: none;

    @media screen and (max-width: 1440px) {
      width: 100vw;
      left: 0;
      display: block;
    }

    @media screen and (max-width: 991px) {
      top: 60px;
    }

    @media screen and (max-width: 576px) {
      top: 30px;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;

  @media screen and (max-width: 991px) {
    height: 100px;
  }
`

const Brand = styled(CustomLink)`
  z-index: 12;
  & > svg {
    height: 86px;
    width: 127px;
  }

  @media screen and (max-width: 576px) {
    & > svg {
      height: auto;
      width: 90px;
    }
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${props => props.theme.color.face.light};
  height: 7px;
  border-radius: 5px;
  width: 90%;
  margin: 8px auto;
`

const Header = ({ setIsScrollable }) => {
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 97}) {
        acf {
          menu {
            link {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <SmallHeaderBackground />
      <HeaderBackground />
      <Container className="container">
        
        <Brand to="/">
          <Logo />
        </Brand>

        <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
        <MenuDesktop fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const OrangeBackground = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;
`

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.face.light};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;
  clip-path: polygon(100% 0, 100% 60%, 60% 100%, 0 100%, 0 0);
  display: flex;
  justify-content: center;
  align-items: center;

  & > ul {
    padding: 0 0 0;
    list-style-type: none;
    text-align: left;
    width: 60%;
    margin: 0 auto;
    position: relative;
    top: -80px;

    & li {
      padding: 0;

      & a {
        font-size: ${props => props.theme.font.size['26']};
        font-weight: ${props => props.theme.font.weight.xl} !important;
        color: ${props => props.theme.color.text.main};
        text-transform: uppercase;
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 35px;
  top: 25px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: 20px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.main};
    position: absolute;
    content: ' ';
    height: 41px;
    width: 7px;
    border-radius: 5px;

  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const MenuMobile = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <>
          <OrangeBackground />
          <MenuWrapper>
            {/* <Brand className="ml-3" to="/">
              <Logo />
            </Brand> */}
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu fields={fields} />
            </ul>
          </MenuWrapper>
        </>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

const MenuDesktop = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  z-index: 12;
  padding: 0 0 0 80px;
  & > a {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: none;

    &[aria-current] {

    }

    &:hover {
      color: ${props => props.theme.color.face.contrast};
    }
  }
`

const Menu = ({ fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index}>
        <CustomLink to={url}>{title}</CustomLink>
      </Item>
    ))}
  </>
)

export default Header
