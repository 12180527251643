import React from 'react'

// Elements
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Images
import Mail from 'img/mail-share.inline.svg'
import Facebook from 'img/facebook-share.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'
import Whatsapp from 'img/whatsapp-share.inline.svg'

import NewLinkedin from 'img/linkedin.png'

// Third Party
import styled from 'styled-components'

const prefix = 'https://www.hop4job.nl';

const Content = styled(ParseContent)`
  h2 {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['18']};
    font-weight: ${props => props.theme.font.weight.xl};
    line-height: 22px;
    padding: 0 0 10px 0;
  }

  p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
  }

  a {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
  }

  table {
    width: 100%;
  }
`

const StyledImage = styled(Image)`
  width: 159px;
  height: 176px;
  border-radius: 5px;
`

const StyledShare = styled.div`
  background-color: ${props => props.theme.color.face.main};
  border-radius: 5px;
  padding: 20px 15px 20px 15px;
  max-width: 410px;
  margin-top: 70px;
`



const Share = ({ fields, path }) => {
  return (
    <StyledShare className="container">
      <div className="row">
        <div className="col-12 pt-2 mb-2">
          <Content content={fields.title} />
        </div>
        <div className="col-6 d-flex justify-content-between">
          <a href={`mailto:?subject=Kijk%20deze%20vacature&amp;body=${prefix}${path}`} target="_blank"><Mail /></a>
          <a href={`https://www.linkedin.com/shareArticle?url=${prefix}${path}`} target="_blank"><img src={NewLinkedin} alt=""/></a>
          <a href={`https://www.facebook.com/sharer/sharer.php?u=${prefix}${path}`} target="_blank"><Facebook /></a>
          <a href={`https://wa.me?text=${prefix}${path}`} target="_blank"><Whatsapp /></a>
        </div>
      </div>
      
    </StyledShare>
  )
}

export default Share
