import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/shared/GravityForm'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    color: ${props => props.theme.color.text.light};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.l};
    line-height: 29px;
    padding-bottom: 30px;
  }
`

const StyledContactForm = styled.div`
  padding: 30px 40px 30px 40px;
  background-color: ${props => props.theme.color.face.contrast};
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  height: 100%;

  @media screen and (max-width: 576px) {
    padding: 30px 30px 30px 30px;
  }
`

const ContactForm = ({ fields }) => {
  return (
    <StyledContactForm>
      <Content content={fields.description} />
      <GravityForm id={1} />
    </StyledContactForm>
  )
}

export default ContactForm
