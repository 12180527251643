import React from 'react'

// Elements
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size['18']};
    font-weight: ${props => props.theme.font.weight.xl};
    line-height: 22px;
    padding: 0 0 10px 0;
  }

  p {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
  }

  a {
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.sm};
  }

  table {
    width: 100%;
  }
`

const StyledImage = styled(Image)`
  width: 159px;
  height: 176px;
  border-radius: 5px;
`

const StyledCtaQuestion = styled.div`
  background-color: ${props => props.theme.color.face.main};
  border-radius: 5px;
  padding: 20px 15px 20px 15px;
  max-width: 410px;
`

const CtaQuestion = ({ fields }) => {
  return (
    <StyledCtaQuestion className="container mt-5">
      <div className="row">
        <div className="col-6 pt-2">
          <Content content={fields.description} />
        </div>
        <div className="col-6 d-flex justify-content-center align-items-center">
          <StyledImage src={fields.image} />
        </div>
      </div>
      
    </StyledCtaQuestion>
  )
}

export default CtaQuestion
