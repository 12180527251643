import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { Helmet } from 'react-helmet'

// Language
import { getLanguage } from 'services/language'

// Icon
import favicon from 'img/favicon.png'

const query = graphql`
  {
    site {
      siteMetadata {
        pathPrefix
        title
        description
        url
      }
    }
  }
`

const getYoastMeta = data => {
  const response = { title: null, description: null }

  if(data) {
    const yoastTitleObj = data.find(
      ({ property }) => property === 'og:title'
    )

    if(yoastTitleObj) {
      response.title = yoastTitleObj.content
    }

    const yoastDescriptionObj = data.find(
      ({ name }) => name === 'description'
    )

    if(yoastDescriptionObj) {
      response.description = yoastDescriptionObj.content
    }
  }

  return response
}

const removeHTML = content => {
  let newContent = content

  if(typeof document !== 'undefined') {
    const titleHtml = newContent
    const div = document.createElement('div')
    div.innerHTML = titleHtml
    newContent = div.innerText
  }

  return newContent
}

function SEO({
  title,
  description,
  path,
  image,
  type,
  yoast,
}) {
  const language = getLanguage()

  return (
    <StaticQuery
      query={query}
      render={queryResponse => {
        const yoastMeta = getYoastMeta(yoast)

        const seoTitle = title || yoastMeta.title || queryResponse.site.siteMetadata.title || ''
        const seoDescription = description || yoastMeta.description || queryResponse.site.siteMetadata.description || ''
        const seoPath = `${queryResponse.site.siteMetadata.url}${path || ''}`
        const seoImage = image || {}
        const seoType = type || 'website'

        if(seoImage.url && seoImage.url.indexOf('http') === -1) {
          seoImage.url = `${queryResponse.site.siteMetadata.url.replace(`/${queryResponse.site.siteMetadata.pathPrefix}`, '')}${seoImage.url}`
        }

        return (
          <>
            <GatsbySeo 
              title={removeHTML(seoTitle)}
              description={removeHTML(seoDescription)}
              canonical={seoPath}
              locale={language}
              openGraph={{
                type: seoType,
                url: seoPath,
                title: seoTitle,
                description: seoDescription,
                images: [
                  seoImage
                ]
              }}
            />
            <Helmet>
              <html lang="nl" />
              <link rel="icon" href={favicon} />

              <script
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid="76b83314-03ba-4e2f-bb5e-8bcd4db88974"
                data-blockingmode="auto"
                type="text/javascript" 
              />

            </Helmet>
          </>
        )
      }}
    />
  )
}

export default SEO
