import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Theme'

// Components
import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'
import OpenSollicitatie from 'components/elements/Modal/OpenSollicitatie'

// CSS
import 'styles/all.scss'

// Third Party
import { AnimatePresence } from 'framer-motion'


const Content = styled.div`
  padding-top: 180px;

  @media screen and (max-width: 768px) {
    padding-top: 120px;
  }

  @media screen and (max-width: 430px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 380px) {
    padding-top: 70px;
  }
`

const Layout = ({ location, children }) => {
  const [isSticky, setSticky] = useState(false)
  const [isScrollable, setIsScrollable] = useState(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  const [open, setOpen] = useState(false)
  const hash = location && location.hash

  useEffect(() => {
    if (hash === '#open-sollicitatie') {
      setOpen(true);
      setIsScrollable(false)
    } else {
      setOpen(false)
      setIsScrollable(true)
    }
  }, [hash])

  return (
    <Theme isScrollable={isScrollable}>

      <AnimatePresence>
        {open && (
          <OpenSollicitatie />
        )}
      </AnimatePresence>

      <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
      <Content isSticky={isSticky}>{children}</Content>
      <Footer location={location} />
    </Theme>
  )
}

export default Layout
