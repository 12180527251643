import React, { createContext, useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

export const BlogContext = createContext({})

export const BlogProvider = BlogContext.Provider
export const BlogConsumer = BlogContext.Consumer

export default function Blogik({ settings: options, children }) {
  const settings = { ...{
    id: 'vacature',
    postIds: [],
    limit: 6,
    usePagination: false,
    queryString: ''
   }, ...options }

  const {
    blog: { edges: blogEdges },
    vacature: { edges: vacatureEdges },
    functies: {
      nodes: functies
    }
  } = useStaticQuery(graphql`
    {
      blog: allWordpressPost(sort: {order: ASC, fields: date}) {
        edges {
          node {
            ...postsFragment
          }
        }
      }

      vacature: allWordpressWpVacature(filter: {wordpress_id: {ne: 227}}) {
        edges {
          node {
            ...generalVacatureFragment
          }
        }
      }

      functies: allWordpressWpFunctie {
        nodes {
          name
          wordpress_id
        }
      }
    }
  `)

  const edgeLiterals = {
    'blog': blogEdges,
    'vacature': vacatureEdges,
    'default': vacatureEdges
  }

  const edges = edgeLiterals[settings.id] || edgeLiterals.default

  const [selectedFilters, setSelectedFilters] = useState([])

  // Custom Filters
  const [brancheFilters, setBrancheFilters] = useState([])
  const [functieFilters, setFunctieFilters] = useState([])

  const [offset, setOffset] = useState(1)

  const setSelectedFiltersLocal = filter => {
    const newSelectedFilters = [ ...selectedFilters ]

    const filterIndex = newSelectedFilters.indexOf(filter)

    if(filterIndex === -1) {
      newSelectedFilters.push(filter)
    } else {
      newSelectedFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`${settings.id}-filters`, JSON.stringify(newSelectedFilters))
    }

    setSelectedFilters(newSelectedFilters)
  }

  const setBrancheFiltersLocal = filter => {
    const newBrancheFilters = [ ...brancheFilters ]

    const filterIndex = newBrancheFilters.indexOf(filter)

    if(filterIndex === -1) {
      newBrancheFilters.push(filter)
    } else {
      newBrancheFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`branche-filters`, JSON.stringify(newBrancheFilters))
    }

    setBrancheFilters(newBrancheFilters)
  }

  const setFunctieFiltersLocal = filter => {
    const newFunctieFilters = [ ...functieFilters ]

    const filterIndex = newFunctieFilters.indexOf(filter)

    if(filterIndex === -1) {
      newFunctieFilters.push(filter)
    } else {
      newFunctieFilters.splice(filterIndex, 1)
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`functie-filters`, JSON.stringify(newFunctieFilters))
    }

    setFunctieFilters(newFunctieFilters)
  }

  const setOffsetLocal = newOffset => {
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(`${settings.id}-offset`, newOffset)
    }

    setOffset(newOffset)
  }

  const incrementOffset = () => {
    let newOffset = parseFloat(offset)

    newOffset += 1

    setOffsetLocal(newOffset)
  }

  const decrementOffset = () => {
    let newOffset = parseFloat(offset)

    newOffset -= 1

    setOffsetLocal(newOffset)
  }

  const isSelected = filter => selectedFilters.indexOf(filter) !== -1

  const brancheSelected = filter => brancheFilters.indexOf(filter) !== -1

  const filterPosts = (posts, { ids }) => posts.filter(({ node }, index) => {
    let response = true

    if(settings.limit !== null && (((index + 1) > (offset * settings.limit)) || (settings.usePagination && offset > (index + 1)))) {
      return false
    }

    if(ids.length > 0 && ids.indexOf(node.wordpress_id) === -1) {
      return false
    }

    if(selectedFilters.length > 0) {
      response = false

      node.categories.forEach(category => {
        if(selectedFilters.indexOf(category.wordpress_id) !== -1) {
          response = true
        }
      })
    }

    if(brancheFilters.length > 0) {
      response = false

      node.branche.forEach(branche => {
        if(brancheFilters.indexOf(`${branche.term_id}`) !== -1) {
          response = true
        }
      })
    }

    if(functieFilters.length > 0) {
      response = false

      node.functie.forEach(functie => {
        if(functieFilters.indexOf(`${functie.term_id}`) !== -1) {
          response = true
        }
      })
    }

    return response
  })

  useEffect(() => {
    if (settings.queryString !== '') {
      settings.queryString.slice(1, settings.queryString.length).split('&').forEach(q => {
        const qList = q.split('=')

        if (qList[0] === 'branche') {
          setBrancheFiltersLocal(qList[1])
        }

        if (qList[0] === 'query') {
          const queryList = []
          qList[1].split(',').forEach(i => {
            const foundItem = functies.filter(f => f.name === i)[0]
            if (typeof foundItem !== 'undefined') {
              queryList.push(foundItem.wordpress_id.toString())
            }
          })
          setFunctieFilters(queryList)
        }
      })
    }

    if(typeof localStorage !== 'undefined') {
      const storageSelectedFilters = localStorage.getItem(`${settings.id}-filters`)
      const storageBrancheFilters = localStorage.getItem(`branche-filters`)
      const storageOffset = localStorage.getItem(`${settings.id}-offset`)

      if (storageSelectedFilters) {
        setSelectedFilters(JSON.parse(storageSelectedFilters))
        setBrancheFilters(JSON.parse(storageBrancheFilters))
      }

      if (storageOffset) {
        setOffset(storageOffset)
      }
    }
  }, [])

  const posts = filterPosts(edges, {
    ids: settings.postIds
  })

  return (
    <BlogProvider
      value={{
        setSelectedFilters: setSelectedFiltersLocal,
        selectedFilters,
        setBrancheFilters: setBrancheFiltersLocal,
        setBrancheFiltersNatural: setBrancheFilters,
        brancheFilters,
        setFunctieFilters: setFunctieFiltersLocal,
        setFunctieFiltersNatural: setFunctieFilters,
        functieFilters,
        brancheSelected,
        isSelected,
        setOffset: setOffsetLocal,
        incrementOffset,
        decrementOffset,
        offset,
        limit: settings.limit,
        filterPosts,
        unfilteredPosts: edges,
        posts,
        hasPosts: posts.length > 0,
        showMoreButton: edges.length > posts.length && (offset * settings.limit) < edges.length,
        showLessButton: offset > 1,
      }}
    >
      {children}
    </BlogProvider>
  )
}

const Button = styled.div`
  outline: none;

  &:focus {
    outline: none;
  }
`

export const BlogFilter = ({ className, children, id }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            data-active={context.isSelected(id) ? 1 : 0}
            onClick={() => {
              context.setSelectedFilters(id)
            }}
            onKeyPress={() => { return null }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

export const BlogButton = ({ className, children, increment = true }) => {
  return (
    <BlogConsumer>
      {(context) => {
        return (
          <Button
            className={className}
            role="button"
            onClick={() => {
              if(increment) {
                context.incrementOffset()
              } else {
                context.decrementOffset()
              }
            }}
            onKeyPress={() => { return null }}
            tabIndex={0}
          >
            {children}
          </Button>
        )
      }}
    </BlogConsumer>
  )
}

export const BlogPagination = ({ className }) => {
  return (
    <BlogConsumer>
      {(context) => {
        const paginations = context.unfilteredPosts.length / context.limit
        const pages = []

        for(let i = 0; i < paginations; i += 1) {
          pages.push(i + 1)
        }

        return (
          <div
            className={className}
          >
            {pages.map(page => (
              <Button
                key={page}
                role="button"
                data-active={parseFloat(page) === parseFloat(context.offset) ? 1 : 0}
                onClick={() => {
                  context.setOffset(page)
                }}
                onKeyPress={() => { return null }}
                tabIndex={0}
              >
                {page}
              </Button>
            ))}
          </div>
        )
      }}
    </BlogConsumer>
  )
}
