import React, { useState } from 'react'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Search from 'img/H_search.inline.svg'
import Facebook from 'img/H_facebook.inline.svg'
import Linkedin from 'img/H_linkedin.inline.svg'

// Elements
import CustomLink from 'components/shared/CustomLink'
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Third Party
import styled from 'styled-components'


const StyledFooter = styled.div`
  margin-left: auto;
  margin-right: auto;
  /* width: 1440px; */
  background-color: ${props => props.theme.color.face.main};
  color: ${props => props.theme.color.text.secondary};

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`

const Brand = styled(CustomLink)`
  z-index: 12;
  & > svg {
    height: 68px;
    width: 100px;
    margin-bottom: 69px;
  }
`

const InnerColumn = styled.div`
  p {
    line-height: 26px !important;
    margin: 0;

    b {
      color: ${props => props.theme.color.text.contrast};
      font-weight: ${props => props.theme.font.weight.xl};
    }
  }
`

const InputContainer = styled.div`
  input {
    -webkit-appearance: none;
  }
`

const StyledInput = styled.input`
  background-color: ${props => props.theme.color.face.input};
  padding-left: 15px;
  padding-right: 15px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 50px;
  border: none;
  outline: none;
  width: 100%;

  &::placeholder {
    color: rgba(0,0,0,0.4);
    font-weight: ${props => props.theme.font.weight.s};
    font-size: ${props => props.theme.font.size.m};
  }
`

const StyledButtonAlt = styled(ButtonAlt)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 50px;

  a {
    padding: 11px 12px 11px 12px;
  }
`

const Column = styled.div`
  color: ${props => props.theme.color.text.light};

  h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    padding: 50px 0 50px 0;

    @media screen and (max-width: 991px) {
      padding: 30px 0 0px 0;
    }
  }
  
  p {
    line-height: 36px;
    margin: 0;
    font-weight: ${props => props.theme.font.weight.s};
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;
    }
  }
`

const StyledFacebook = styled(Facebook)`
  &:hover {
    path {
      fill: ${props => props.theme.color.face.contrast};
    }
  }
`

const StyledLinkedin = styled(Linkedin)`
  &:hover {
    g {
      g {
        g {
          path {
            fill: ${props => props.theme.color.face.contrast};
          }
        }
      }
    }
  }
`

const Social = styled.a``

const Footer = ({ location }) => {
  const [query, setQuery] = useState('')
  const {
    fields
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          menu {
            link {
              title
              url
            }
          }
          branches {
            link {
              title
              url
            }
          }
          contact {
            address
            zipcode
            phonenumber {
              title
              url
            }
            email {
              title
              url
            }
            linkedin
            facebook
          }
        }
      }
    }
  `)

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      navigate(`/zoek?query=${query}`)
    }
  }

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <Column className="col-lg-3">
            <Brand to="/">
              <Logo />
            </Brand>
            
            <InnerColumn>
              <p>{fields.acf.contact.address}</p>
              <p className="pb-4">{fields.acf.contact.zipcode}</p>
              <p>
                <a href={fields.acf.contact.phonenumber.url}>{fields.acf.contact.phonenumber.title}</a>
              </p>
              <p>
                <a href={fields.acf.contact.email.url}><b>{fields.acf.contact.email.title}</b></a>
              </p>
            </InnerColumn>
          </Column>
          <Column className="col-6 col-lg-2">
            <h2>Hop4Job</h2>
            <ul>
              {fields.acf.menu.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>
                    <p>{title}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </Column>
          <Column className="col-6 col-lg-3">
            <h2>Branches</h2>
            <ul>
              {fields.acf.branches.map(({ link: { url, title } }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>
                  <Link to={url}>
                    <p>{title}</p>
                  </Link>
                </li>
              ))}
            </ul>
          </Column>
          <Column className="col-lg-4">
            <h2>Zoeken</h2>
            {location && location.pathname !== '/zoek' && (
              <InputContainer className="d-flex pb-5">
                <StyledInput
                  placeholder="Doorzoek de website"
                  // value={query}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                />
                <Link to={`/zoek?query=${query}`}>
                  <StyledButtonAlt>
                    <Search />
                  </StyledButtonAlt>
                </Link>
              </InputContainer>
            )}
            <div className="d-flex">
              <Social target="__blank" href={fields.acf.contact.linkedin} className="mr-3">
                <StyledLinkedin />
              </Social>
              <Social target="__blank" href={fields.acf.contact.facebook}>
                <StyledFacebook />
              </Social>
            </div>
          </Column>
        </div>
        
      </div>
    </StyledFooter>
  )
}

export default Footer
