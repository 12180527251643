/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import Filter from 'components/elements/Vacancies/Filter'
import SelectedFilters from 'components/elements/Vacancies/SelectedFilters'
import AutocompleteFilter from 'components/elements/Vacancies/AutocompleteFilter'
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Blogik
import Vacancies from 'components/related/Vacancies'
import Blogik, { BlogConsumer } from 'components/shared/Blogik'

// Third Party
import styled, { css } from 'styled-components'
import { useMedia } from 'react-use'


const StyledVacanciesFilter = styled.div`
  padding-top: 20px;
  padding-bottom: 80px;

  @media screen and (max-width: 991px) {
    padding-top: 20px;
    padding-bottom: 0px;
  }

  @media screen and (max-width: 450px) {
    padding-top: 0;
  }
`

const MobileFilterButtonContainer = styled.div`
  @media screen and (min-width: 992px) {
    display: none;
  }

  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 8px rgba(0,0,0,.3);
  position: fixed;
  width: 100vw;
  height: fit-content;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 999;
`

const MobileFilterButton = styled(ButtonAlt)`
  width: 100%;
  text-align: center;
  outline: none;
`

const FilterContainer = styled.div`
  margin-top: 32px;
  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  border-radius: 5px;
  padding: 30px 30px 20px 30px;
  max-width: 320px;

  h2 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
  }

  @media screen and (max-width: 991px) {
    max-width: unset;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    margin-top: 0;
    border-radius: 0;
    z-index: 998;

    ${props => props.open ? css`
      display: block;
    ` : css`
      display: none;
    `}
  }
`

const VacanciesFilter = ({ location }) => {
  const [open, setOpen] = useState(false)
  const isMobile = useMedia('(max-width: 991px)')
  const {
    branches: {
      nodes: branches
    },
    functies: {
      nodes: functies
    }
  } = useStaticQuery(graphql`{
    branches: allWordpressWpBranche {
      nodes {
        name
        wordpress_id
      }
    }
    functies: allWordpressWpFunctie {
      nodes {
        name
        wordpress_id
      }
    }
  }`)

  useEffect(() => {
    if (open && isMobile) {
      // Gatsby
      document.body.style.height = '60vh'
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'hidden'
      document.body.style.position = 'relative'
    } else {
      // Body
      document.body.style.height = 'unset'
      document.body.style.overflowX = 'hidden'
      document.body.style.overflowY = 'visible'
      document.body.style.position = 'relative'
    }
    
  }, [open])

  return (
    <StyledVacanciesFilter className="container">
      <Blogik
        settings={{
          limit: 100,
          id: 'vacature',
          queryString: location.search
        }}
      >
        <BlogConsumer>
          {props => {
            return (
              <div className="row">
                <div className="col-lg-4">
                  <MobileFilterButtonContainer>
                    <SelectedFilters
                      mobile
                      filters={[
                        {
                          type: 'branche', 
                          list: props.brancheFilters
                        },
                        {
                          type: 'functie',
                          list: props.functieFilters
                        }
                      ]}
                      givenFilters={[
                        {
                          type: 'branche',
                          list: branches
                        },
                        {
                          type: 'functie',
                          list: functies
                        }
                      ]}
                      setFilters={[
                        {
                          type: 'branche',
                          function: props.setBrancheFiltersNatural
                        },
                        {
                          type: 'functie',
                          function: props.setFunctieFiltersNatural
                        }
                      ]}
                      count={props.posts.length}
                    />
                    <div onClick={() => setOpen(!open)}>
                      <MobileFilterButton>
                        {open ? `BEKIJK ${props.posts.length} ${props.posts.length === 1 ? `RESULTAAT` : `RESULTATEN`}` : `FILTER`}
                      </MobileFilterButton>
                    </div>
                  </MobileFilterButtonContainer>
                  <FilterContainer open={open}>
                    <h2>Filter</h2>
                    <AutocompleteFilter
                      filters={[
                        {
                          type: 'branche', 
                          list: props.brancheFilters
                        },
                        {
                          type: 'functie',
                          list: props.functieFilters
                        }
                      ]}
                      givenFilters={[
                        {
                          type: 'branche',
                          list: branches
                        },
                        {
                          type: 'functie',
                          list: functies
                        }
                      ]}
                      setFilters={[
                        {
                          type: 'branche',
                          function: props.setBrancheFilters
                        },
                        {
                          type: 'functie',
                          function: props.setFunctieFilters
                        }
                      ]}
                    />
                    <Filter
                      title="Branche"
                      givenFilters={branches}
                      filter={props.brancheFilters}
                      setFilter={props.setBrancheFilters}
                    />
                    <Filter
                      title="Functie"
                      givenFilters={functies}
                      filter={props.functieFilters}
                      setFilter={props.setFunctieFilters}
                    />
                  </FilterContainer>
                </div>
                <div className="col-lg-8 position-relative">
                  <SelectedFilters 
                    filters={[
                      {
                        type: 'branche', 
                        list: props.brancheFilters
                      },
                      {
                        type: 'functie',
                        list: props.functieFilters
                      }
                    ]}
                    givenFilters={[
                      {
                        type: 'branche',
                        list: branches
                      },
                      {
                        type: 'functie',
                        list: functies
                      }
                    ]}
                    setFilters={[
                      {
                        type: 'branche',
                        function: props.setBrancheFiltersNatural
                      },
                      {
                        type: 'functie',
                        function: props.setFunctieFiltersNatural
                      }
                    ]}
                    count={props.posts.length}
                  />
                  <Vacancies posts={props.posts} />
                </div>
                

                {/* <div className="col-12">
                  <BlogPagination />
                </div> */}
              </div>
            )
          }}
        </BlogConsumer>
      </Blogik>
      
    </StyledVacanciesFilter>
  )
}

export default VacanciesFilter
