/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third Party
import styled, { css } from 'styled-components'


const StyledSelectedFilters = styled.div`
  position: relative;
  height: 22px;
  margin-bottom: 10px;
  font-size: ${props => props.theme.font.size.sm};
  display: flex;

  @media screen and (max-width: 991px) {
    ${props => props.mobile ? css`
      display: flex;
      align-items: center;
      height: fit-content;
      margin-bottom: 0;
      overflow-x: scroll;
      overflow-y: visible;
    ` : css`
      display: none;
    `}
  }
`

const Filter = styled.div`
  border: 1px solid ${props => props.theme.color.face.main};
  border-radius: 5px;
  min-width: 80px;
  padding: 2px 20px 2px 20px;
  text-align: center;
  position: relative;
  top: -4px;
  cursor: pointer;
  margin: 0 0 0 15px;
  height: 26px;

  &::before {
    content: "X";
    position: absolute;
    background-color: ${props => props.theme.color.face.main};
    color: ${props => props.theme.color.text.light};
    font-size: ${props => props.theme.font.size.xxs};
    font-weight: ${props => props.theme.font.weight.l};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    top: -5px;
    right: -5px;
    border-radius: 7px;
  }

  @media screen and (max-width: 991px) {
    margin: 0 15px 0 0;
  }
`

const FilterListWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: -15px;
  height: fit-content;
  padding: 2px 10px 2px 10px;
`

const UpperWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: visible;
  width: 400px;
  height: 50px;
  top: -12px;
`

const SelectedFilters = ({ mobile, givenFilters, filters, setFilters, count }) => {
  
  const handleClick = (type, event) => {
    setFilters.filter(sf => sf.type === type)[0].function([...filters.filter(f => f.type === type)[0].list].filter(cl => parseInt(cl, 0) !== event))
  }

  return (
    <StyledSelectedFilters mobile={mobile} className="justify-content-between">
      {!mobile ? (
        <>
          <div className="d-flex">
            Filters geselecteerd:
            <UpperWrapper className="position-relative">
              <FilterListWrapper className="d-flex">
                {filters.map(filterList => (
                  <>
                    {filterList.list.map((filter, index) => (
                      <Filter onClick={() => handleClick(filterList.type, parseInt(filter, 0))} key={index}>
                        {givenFilters.filter(gf => gf.type === filterList.type)[0].list.filter(l => l.wordpress_id === parseInt(filter, 0))[0].name}
                      </Filter>
                    ))}
                  </>
                ))}
              </FilterListWrapper>
            </UpperWrapper>
            
          </div>
          <div>
            {`${count} vacature${(count > 1 || count === 0) ? `s` : ``} gevonden`}
          </div>
        </>
      ) : (
        <>
          {filters.length !== 0 && (
            <div className="d-flex">
              {filters.map(filterList => (
                <div className={`d-flex ${filterList.list.length !== 0 ? `my-2` : ``}`}>
                  {filterList.list.map((filter, index) => (
                    <Filter onClick={() => handleClick(filterList.type, parseInt(filter, 0))} key={index}>
                      {givenFilters.filter(gf => gf.type === filterList.type)[0].list.filter(l => l.wordpress_id === parseInt(filter, 0))[0].name}
                    </Filter>
                  ))}
                </div>
              ))}
            </div>
          )}
        </>
      )}
 
    </StyledSelectedFilters>
  )
}

export default SelectedFilters
