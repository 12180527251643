/* eslint-disable react/no-array-index-key */
import React from 'react'

// Elements
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'


const Content = styled(ParseContent)`
  h2 {
    font-size: ${props => props.theme.font.size['36']};
    font-weight: ${props => props.theme.font.weight.xl};
  }
`

const StyledImage = styled(Image)`
  height: 200px;
  width: 180px;

  padding-left: 40px;
  padding-right: 40px;

  img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 576px) {
    height: 130px;
  }
`

const ImageWrapper = styled.div`
  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
`

const StyledClients = styled.div``

const Clients = ({ fields }) => {
  return (
    <StyledClients className="container">
      <Content content={fields.description} />
      <ImageWrapper className="row justify-content-center">
        {fields.repeater.map((i, index) => (
          <StyledImage className="col-4 col-lg" key={index} src={i.image} />
        ))}
      </ImageWrapper>
    </StyledClients>
  )
}

export default Clients
