/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Elements
import Image from 'components/shared/Image'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'


const StyledWerkwijze = styled.div`
  padding: 50px 0 50px 0;
`

const Content = styled(ParseContent)`
  padding: 0 0 60px 0;

  h2 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size['36']};
    line-height: 44px;
  }

  p {
    font-weight: ${props => props.theme.font.weight.l};
    line-height: 19px;
  }
`

const StepContent = styled(ParseContent)`
  height: 50px;  

  p {
    font-weight: ${props => props.theme.font.weight.xl};
    line-height: 19px;
  }
`

const StepShadow = styled(motion.div)`
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  height: 270px;
  width: 220px;
  top: -54px;
  left: -5px;
  background-color: ${props => props.theme.color.face.contrast};

  @media screen and (max-width: 991px) {
    width: 340px;
    left: -20px;
    top: -15px;
  }
`

const StyledStep = styled.div`
  width: 180px;
  position: relative;
  margin: 0 15px 0 15px;

  ${props => props.selected && css`
    background-color: ${props.theme.color.face.contrast};

    p, h3 {
      color: ${props.theme.color.text.light};
      z-index: 2;
      position: relative;
    }

    &::after {
      
    }

    @media screen and (max-width: 991px) {
    }
  `}

  ${props => props.index !== 4 && css`
    &::before {
      content: "";
      position: absolute;
      top: 35px;
      right: -15px;
      height: 100px;
      width: 1px;
      background-color: ${props.theme.color.face.border};

      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  `}

  h3 {
    font-size: ${props => props.theme.font.size['26']};
    font-weight: ${props => props.theme.font.weight.xl};
  }
  

  @media screen and (max-width: 991px) {
    margin: 40px 0 40px 0;
  }
`

const StyledImage = styled(Image)`
  height: 60px;
  width: 60px;
  z-index: 2;
  img {
    object-fit: contain !important;
  }
  margin: 0 0 20px 0;
`

const StepWrapper = styled(motion.div)`
  @media screen and (max-width: 991px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const StepContainerWrapper = styled.div`
  @media screen and (max-width: 991px) {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
`

const StepContainer = styled.div`
  padding: 35px 0 35px 0;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
  background-color: ${props => props.theme.color.face.light};

  @media screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
`

const Werkwijze = ({ fields }) => {
  const [selected, setSelected] = useState(1)

  return (
    <StyledWerkwijze className="container">
      <Content content={fields.description} />
      <StepContainerWrapper className="d-flex justify-content-center">
        <StepContainer className="d-flex justify-content-center">
          {fields.steps.map((step, index) => (
            <StepWrapper
              onTapStart={() => setSelected(index)}
              onHoverStart={() => setSelected(index)}
              key={index}
              className="position-relative"
            >
              <AnimatePresence>
                {index === selected && (
                  <StepShadow
                    initial={{ y: 20 }}
                    animate={{ y: 0 }}
                    exit={{ opacity: 0, transition: { duration: .3 } }}
                    transition={{ duration: .3 }}
                  />
                )}
              </AnimatePresence>
              <StyledStep selected={index === selected} index={index}>
                <div className="justify-content-center d-flex">
                  <StyledImage src={index === selected ? step.hover_image : step.image} />
                </div>
                <StepContent content={step.description} />
                <div className="justify-content-center d-flex">
                  <h3 className="mb-0">{index + 1}</h3>
                </div>  
              </StyledStep>
            </StepWrapper>  
          ))}
        </StepContainer>
      </StepContainerWrapper>
    </StyledWerkwijze>
  )
}

export default Werkwijze
