import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonAlt from 'components/elements/Button/ButtonAlt'

// Third Party
import styled from 'styled-components'

const Vacancies = ({ posts }) => {
  return (
    <div className="row">
      {posts.map(({ node }) => (
        <div key={node.wordpress_id} className="col-lg-12 d-flex justify-content-center">
          <Vacancy fields={node} />
        </div>
      ))}
      <div className="col-lg-12 d-flex justify-content-center">
        <OpenApply />
      </div>
    </div>
  )
}


const Content = styled(ParseContent)`
  padding: 20px 0 0 0;
  font-size: ${props => props.theme.font.size.sm};
  line-height: 27px;
`

const StyledVacancy = styled.div`
  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  padding: 30px 40px 30px 40px;
`

const Title = styled.div`
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size.l};
`

const Tags = styled.div`
  font-size: ${props => props.theme.font.size.sm};
  line-height: 17px;
`

const StyledButtonAlt = styled(ButtonAlt)`
  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
`

const Vacancy = ({ fields }) => {
  return (
    <StyledVacancy>
      <div className="row">
        <div className="col-lg-7">
          <Title>{fields.title}</Title>
          <Tags>{`${fields.locatie[0].name} | ${fields.uren[0].name} | ${fields.branche[0].name}`}</Tags>
          <Content content={fields.excerpt} />
        </div>
        <div className="col-lg-5 pt-lg-0 pt-4 d-flex align-items-end justify-content-end">
          <StyledButtonAlt to={fields.path}>
            Bekijk vacature
          </StyledButtonAlt>
        </div>
      </div>
    </StyledVacancy>
  )
}

const StyledOpenApply = styled.div`
  background-color: ${props => props.theme.color.face.main};
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  border-radius: 5px;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  padding: 30px 40px 30px 40px;
`

const OpenTitle = styled.div`
  font-weight: ${props => props.theme.font.weight.xl};
  font-size: ${props => props.theme.font.size.l};
  color: ${props => props.theme.color.text.light};
`

const OpenContent = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  padding-bottom: 50px;
`

const OpenApply = () => {
  return (
    <StyledOpenApply>
      <div className="row">
        <div className="col-12">
          <OpenTitle>Niet kunnen vinden wat je zocht?</OpenTitle>
        </div>
        <div className="col-lg-7"> 
          <OpenContent content="Stuur een open sollicitatie en wie weet hebben wij binnenkort een passende baan voor je." />
        </div>
        <div className="col-lg-5 d-flex align-items-end justify-content-end">
          <StyledButtonAlt to="#open-sollicitatie">
            Open sollicitatie
          </StyledButtonAlt>
        </div>
      </div>
    </StyledOpenApply>
  )
}

export default Vacancies